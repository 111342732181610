<template>
  <div
    id="top"
    class="home-container text-light px-2 px-lg-5"
    v-bind:class="{
      'header-margin': !isAuthenticated(),
    }"
  >

    <b-container fluid class="px-lg-4 px-xl-5">
      <b-row class="text-center pt-5">
        <b-col cols="12">
          <h5 class="trident">TRIDENT STUDIOS, LONDON</h5>
          <h1 class="heading mt-3 mb-3 pt-4 pb-1">A Little History</h1>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="px-sm-4 px-md-5 px-lg-4 px-xl-5 pt-3">
      <div class="pt-md-5">
        <b-row class="pb-3">
          <b-col cols="12" class="pb-2 pb-md-5">
            <h3 class="text-center text-md-right section-heading">
              The Summer of '68
            </h3>

            <p class="text-center text-md-right section-desc pt-4">
              Trackd has a history of breaking musical ground.<br /><br />

              Today, we’re an award winning business that enables artists<br
                class="d-md-none"
              />
              and fans around<br class="d-none d-md-block d-lg-none" />
              the world to come together. We are unique.<br
                class="d-none d-md-block"
              />
              <br class="d-md-none" /><br class="d-lg-none" />
              The first platform to enable artist creation, fan discovery and<br
                class="d-none d-sm-block d-md-none"
              />
              fair pay for artists;<br class="d-none d-md-block d-lg-none" />
              all in one place; on Trackd.
            </p>
          </b-col>

          <!-- Image -->
          <b-col class="text-center pr-md-0 pt-3 pt-md-0 d-md-none" cols="12">
            <img
              src="../../assets/pages/about/summer68.png"
              class="summer68"
            />
            <p class="pic-caption">
              (Top) The Beatles recorded ‘Hey Jude’ at Trident Studios. <br />
              (Right) Norman and Barry Sheffield at the opening of<br />
              Trident Studios, 8th March 1968. (Left) One of the many<br />
              awards over the years to Trident Studios.
            </p>
          </b-col>

          <!-- Info -->
          <b-col
            cols="12"
            md="6"
            class="mt-4 mt-md-0 px-md-0 pl-3 text-center text-md-left"
          >
            <p class="list-heading">Here's how we got here.</p>
            <br />
            <p class="normal-text">
              In 1968, Trident Studios London opened its doors as a <br />hub
              for innovation in music. Founded by brothers Norman<br />
              and Barry Sheffield; Trident was the world’s first 8-track<br />
              studio and was the alternative to the ‘science lab’<br />
              mentality of rival Abbey Road. <br /><br />
              This revolutionary studio based in Soho recorded major<br />
              artists such as The Beatles, Elton John, David Bowie,<br />
              Queen, The Rolling Stones, Carly Simon, Lou Reed and<br />
              many more. Read more on
              <a
                href="https://en.wikipedia.org/wiki/Trident_Studios"
                style="color: white"
                ><u>wikipedia here</u></a
              >
            </p>
          </b-col>

          <!-- Image -->
          <b-col class="text-right pl-0 d-none d-md-inline-block" cols="6">
            <img
              src="../../assets/pages/about/summer68.png"
              class="summer68"
            />

            <p class="normal-text">
              (Top) The Beatles recorded ‘Hey Jude’ at Trident Studios. <br />
              (Right) Norman and Barry Sheffield at the opening of<br />
              Trident Studios, 8th March 1968. (Left) One of the many<br />
              awards over the years to Trident Studios.
            </p>
          </b-col>
        </b-row>
        <div class="border-white mb-5 mt-5 mt-md-0 section-border-right"></div>
      </div>

      <div id="music-fan" class="pt-0 pb-5">
        <b-row class="pb-5 mb-5">
          <b-col cols="12">
            <h3 class="section-heading text-center text-md-left">
              Back to the Roots
            </h3>
          </b-col>

          <b-col class="mt-1 text-md-left text-center pl-md-0" cols="12" md="6">
            <img
              src="../../assets/pages/about/roots.jpg"
              class="roots pl-3 pl-md-0"
            />
            <p class="pt-3 normal-text">
              Russell Sheffield (bottom left), with Queen and Mott The<br />
              Hoople in December 1973
            </p>
          </b-col>

          <b-col
            class="normal-text px-0 text-center text-md-left"
            cols="12"
            md="6"
          >
            <p class="float-md-right pb-4">
              Trident’s iconic music culture was more than skin deep, taking<br />
              risks on up and coming artists. In 1972 a young band called <br />
              ‘Smile’ were struggling to build a career. Norman and Barry <br />
              Sheffield took a chance, the result being one of the greatest<br />
              rock bands of all time – Queen.
              <br /><br />
              Cut to now - Russell and brother Justin, are the sons of
              Trident<br />
              founder Norman and bring this incredible music heritage, plus a<br />
              mega cast of movers and shakers and know-how; and through <br />
              Trackd are once again creating a revolution.
              <br /><br />
              It starts with innovations such as ChipIn+ which, for the first<br />
              time, enables artists to take control and get paid directly and<br />
              fairly for their work. The Trident DNA runs deep.
              <br /><br />
              We’re on your team. Together the future of music is yours.
            </p>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AboutUs",

  methods: {
    ...mapGetters(["isAuthenticated"]),
    app_store_click() {
      window.open(
        "https://apps.apple.com/gb/app/trackd-songs-start-here/id1496465739",
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
.c-header-buttons-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1000;
  width: 98%;
}

.c-btn-hamburger-icon,
.c-btn-back-icon {
  padding-left: 0px;
  padding-top: 15px;
}

.c-img-hamburger-icon,
.c-img-back-icon {
  width: 52px;
  height: 52px;
}

.home-container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url("../../assets/pages/about/background.jpg");
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* Center and scale the image nicely */
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}

.header-margin {
  margin-top: 100px;
}

.trident {
  font-size: 14px;
  font-weight: 900;
  line-height: 30px;
}

.heading {
  font-size: 30px;
  font-weight: 200;
  line-height: 60px;
}

.section-heading {
  font-size: 24px;
  font-weight: 300;
  line-height: 90px;
}

.summer68 {
  height: 200px;
}

.roots {
  height: 200px;
}

.section-desc {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.normal-text {
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
}

.list-heading {
  font-size: 12px;
  font-weight: 400;
  line-height: 0px;
}

#feature-list li {
  font-size: 11px;
  font-weight: 300;
  line-height: 16px;
}

.section-border-right {
  margin-right: -20px;
  margin-left: 24%;
}

.section-border-left {
  margin-left: -10%;
  width: 90%;
}

.pic-caption {
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
}

@media (min-width: 576px) {
  .trident {
    font-size: 14px;
    font-weight: 900;
    line-height: 30px;
  }

  .heading {
    font-size: 48px;
    font-weight: 200;
    line-height: 90px;
  }

  .section-heading {
    font-size: 24px;
    font-weight: 300;
    line-height: 90px;
  }

  .summer68 {
    height: 275px;
  }

  .roots {
    height: 250px;
  }

  .section-desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .list-heading {
    font-size: 12px;
    font-weight: 400;
    line-height: 0px;
  }
  .section-border-right {
    margin-right: -5%;
    margin-left: 24%;
  }

  .section-border-left {
    margin-left: -10%;
    width: 90%;
  }
}

@media (min-width: 768px) {
  .trident {
    font-size: 14px;
    font-weight: 900;
    line-height: 30px;
  }

  .heading {
    font-size: 60px;
    font-weight: 200;
    line-height: 90px;
  }

  .section-heading {
    font-size: 30px;
    font-weight: 300;
    line-height: 90px;
  }

  .normal-text {
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
  }

  .app-store {
    height: 50px;
  }

  .summer68 {
    height: 250px;
  }

  .roots {
    height: 215px;
  }

  .section-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .list-heading {
    font-size: 14px;
    font-weight: 400;
    line-height: 0px;
  }

  .section-border-right {
    margin-right: -55px;
    margin-left: 24%;
  }

  .section-border-left {
    margin-left: -10%;
    width: 90%;
  }
}

@media (min-width: 992px) {
  .trident {
    font-size: 14px;
    font-weight: 900;
    line-height: 30px;
  }

  .heading {
    font-size: 80px;
    font-weight: 200;
    line-height: 90px;
  }

  .section-heading {
    font-size: 40px;
    font-weight: 300;
    line-height: 90px;
  }

  .normal-text {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
  }

  .summer68 {
    height: 310px;
  }

  .roots {
    height: 270px;
  }

  .section-desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  .list-heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 8px;
  }

  .section-border-right {
    margin-right: -70px;
    margin-left: 24%;
  }

  .section-border-left {
    margin-left: -10%;
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .section-heading {
    font-size: 40px;
    font-weight: 300;
    line-height: 90px;
  }

  .summer68 {
    height: 416px;
  }

  .roots {
    height: 300px;
  }

  .section-desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  }

  .normal-text {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }

  .list-heading {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .section-border-right {
    margin-right: -94px;
    margin-left: 24%;
  }

  .section-border-left {
    margin-left: -10%;
    width: 90%;
  }
}


@media (max-width: 414px) { 
  .home-container {
    background-size: 135%;
  }
}

@media (max-width: 375px) { 

  .home-container {
    background-size: 135%;
  }

}
@media (max-width: 320px) { 

  .roots {
    margin-left: 3px !important;
    width: 100%;
  }

}
</style>
